body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #111;
  color: #ddd;
}

a {
  color: rgb(243, 178, 0);
}

.title {
  margin-left: 10px;
  margin-right: 10px;
  margin-top: auto;
  margin-bottom: auto;
}

.alert {
  bottom: 0;
  margin: auto;
  width: 75%;
  position: fixed;
  background: #b18200;
  color: black;
  text-align: center;
}

.sso-button {
  margin-left: 10px;
  margin-right: 10px;
  margin-top: auto;
  margin-bottom: auto;
}

.align-right {
  text-align: right;
}

.align-center {
  text-align: center;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.flex-center-vertical {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.flex-center {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.mobile-top-bar {
  display: none;
  flex-direction: row;
  justify-content: space-between;
  background-color: #007;
  height:45px;
}

.top-bar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: #007;
}

.toggler{
    z-index:2;
    height: 50px;
    width: 50px;
    position: absolute;
    top: 0;
    left: 0;
    cursor: pointer;
    opacity: 0;
    z-index: 4;
}

.hamburger{
    position: absolute;
    top: -5px;
    left: 0;
    height: 40px;
    width: 40px;
    padding: 0.6rem;
    
    display: flex;
    align-items: center;
    justify-content: center;
}

.hamburger > div{
    position: relative;
    top: 0;
    left: 0;
    background: white;
    height: 2px;
    width: 60%;
    transition: all  0.4s ease;
}

.hamburger > div::before,
.hamburger > div::after{
    content: '';
    position: absolute;
    top: -10px;
    background: white;
    width: 100%;
    height: 2px;
    transition: all  0.4s ease;
    z-index: 4;
}

.hamburger > div::after{
    top: 10px;
}

.toggler:checked + .hamburger > div{
    background: rgba(0,0,0,0);
}

.toggler:checked + .hamburger > div::before{
    top: 0;
    transform: rotate(45deg);
}

.toggler:checked + .hamburger > div::after{
    top: 0;
    transform: rotate(135deg);
}

.menu{
    display: none;
    background: #111;
    width: 0%;
    height: 100vh;
    transition: all 0.4s ease;
    z-index: 3;
    margin-top: 0px;
}

.toggler:checked ~ .menu{
  display: block;
  width: 100%;
}

.menu >  div > ul{
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 70px;
    padding-left: 10px;
    visibility: hidden;
}

.menu > div > ul > li{
    list-style: none;
    padding: 0.5rem;
}

.menu > div > ul > li > a{
    color: black;
    text-decoration: none;
    font-size: 2rem;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.toggler:checked ~ .menu > div > ul{
    transition: visibility 0.4s ease;
    transition-delay: 0.1s;
    visibility: visible;
}

.toggler:checked ~ .menu > div > ul > li > a:hover{
    color: orange;
}

/* Responsive layout - makes a one column layout instead of a two-column layout */
@media (max-width: 800px) {
  .flex-horizontal {
    flex-direction: column;
  }

  .flex-center {
    flex-direction: column;
  }

  .mobile-top-bar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: #007;
  }

  .top-bar {
    display: none;
  }
}
